










import { Component, Vue, Prop } from "vue-property-decorator";
import GetQuoteForm from "./../components/forms/GetQuoteForm.vue";

@Component({
  components: {
    GetQuoteForm,
  },
  metaInfo: {
    title: "Get Quotation",
  },
})
export default class GetQuote extends Vue {
  @Prop({ default: () => ({}) })
  public query!: Record<string, any>;

  onTrackingDone() {
    return;
  }
}
