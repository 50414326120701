






































































































































































import { Component } from "vue-property-decorator";
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { BaseForm } from "./BaseForm";
import "progress-tracker/src/styles/progress-tracker.scss";

interface DHLModel {
  accountNumber: string;
  originCountryCode: string;
  originCityName: string;
  destinationCountryCode: string;
  destinationCityName: string;
  weight: number;
  length: number;
  width: number;
  height: number;
  plannedShippingDate: string;
  isCustomsDeclarable: boolean;
  unitOfMeasurement: string;
}

@Component
export default class GetQuoteForm extends BaseForm {
  public selectedDate: string | null = null;
  public countries: Array<{ code: string; name: string }> = [];
  public selectedOption: string | null = null;
  async created() {
    try {
      const response = await fetch("/countries.json"); // Relative path to the JSON file
      this.countries = await response.json();
    } catch (error) {
      //console.error('Error loading countries:', error);
    }
  }

  public get formattedDate(): string {
    if (this.selectedDate) {
      return new Date(this.selectedDate).toLocaleDateString();
    }
    return "";
  }
  public endpoint = "https://app.respectmart.com/api/dhl";
  public data!: any;
  public isPopupVisible = false;
  public popupContent: any = {};

  closePopup() {
    this.isPopupVisible = false;
  }

  public formData = {
    originCountryCode: "",
    originCityName: "",
    destinationCountryCode: "",
    destinationCityName: "",
    weight: "",
    //length: 0,
    //width: 0,
    // height: 0,
    plannedShippingDate: "",
    isCustomsDeclarable: "",
    //unitOfMeasurement: "",
  };

  async submitForm() {
    try {
      if (!this.endpoint || !this.formData) {
        console.log("Error: Missing required data");
        return;
      }
      const res = await axios.get(this.endpoint, { params: this.formData });
      if (res.data.status == 200) {
        this.popupContent = "";
        //console.log("Server Response :"+JSON.parse(res.data.data))
        const jsonData = JSON.parse(res.data.data);
        const statusCode = jsonData.status;
        if (statusCode == 400 || statusCode == 500) {
          alert(jsonData.detail);
          this.popupContent = {
            //title: 'Response',
            success: JSON.parse(res.data.data).detail,
          };
        } else if (statusCode == 200) {
          console.log(jsonData);
        } else {
          if (jsonData.products.length > 0) {
            console.log(
              "Total Price   " +
                jsonData.products[0].totalPrice[2].price +
                "  " +
                jsonData.products[0].totalPrice[2].priceCurrency
            );
            this.popupContent = "";
            this.popupContent = {
              title: "Total price:  ",
              price: jsonData.products[0].totalPrice[0].price,
              priceCurrency: jsonData.products[0].totalPrice[0].priceCurrency,
            };
            this.isPopupVisible = true;
          }
          //console.log(jsonData.products)
        }

        this.isPopupVisible = true;
      } else if (res.data.status == 422) {
        console.log(res.data.errors);
        this.popupContent = "";
        this.popupContent = {
          //title: 'Error',
          error: res.data.errors,
        };
        this.isPopupVisible = true;
      }
    } catch (error) {
      console.log("Error :" + error);
    }

    // try {
    //   const response = await axios.get(this.endpoint,
    //     {params: this.formData,
    //     headers: {
    //       Authorization: `Basic ${this.base64Credentials}`,
    //     }},
    //   );
    //   console.log("API response:", response.data);

    //   this.formData = {
    //     accountNumber: "",
    //     originCountryCode: "",
    //     originCityName: "",
    //     destinationCountryCode: "",
    //     destinationCityName: "",
    //     weight: 0,
    //     length: 0,
    //     width: 0,
    //     height: 0,
    //     plannedShippingDate: "",
    //     isCustomsDeclarable: false,
    //     unitOfMeasurement: "",
    //   };
    // } catch (error) {
    //   console.error("Error submitting form:", error);
    // }
  }
}
